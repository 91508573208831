<template>
  <div class="church-list template-1">
    <header class="page-header container">
      <h1 class="text-uppercase">{{ translations.tcCampOfficerSelections }}</h1>
    </header>
    <!-- / Page Header -->

    <camp-selector @camp_changed="page_load()" :showCamp="false" :tooltip="translations.tcCurrentHome"
      :i18n="translations.components"></camp-selector>
    <!-- / Camp Selector -->
    <div class="row pt-4">
      <div class="col-6"></div>
      <div class="col-4">
        <b-form-select :title="translations.tcContactedBy" v-model="campPosition" :options="optionCampPositions"
          @change="loadOfficerSelections()" class="g-select flex-0 mr-3 mb-3" required></b-form-select>
      </div>
    </div>
    <data-table :fields="fields" :listType="list_type" :items="campOfficerSelectionsAlter" :toggleMe="true"
      :addItems="addItems" :handleDeleteAction="false" :handleEditAction="false" :handleViewAction="false"
      :csvUrl="csvUrl" :pdfUrl="pdfUrl" :reportName="reportName" :i18n="translations.components"></data-table>
    <!-- / Data Tables -->

    <footer class="page-footer"></footer>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import { countryBreadcrumbMixin } from '@/mixins/breadcrumbMixinFactory'
import DataTable from '@/components/DataTableMemberChurches.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'camp-officer-selections',
  mixins: [translationMixin, countryBreadcrumbMixin],
  data() {
    return {
      translations: {},
      campPosition: '',
      list_type: 'camp_member_churches',
      fields: [
        { key: 'sort_name', label: 'Name', sortable: true },
        { key: 'camp_name', label: 'Camp', sortable: true },
      ],
      hiddenItems: {
        display: false,
      },
      addItems: {
        display: false,
        text: 'Add Speaker', // Add Visit
        action: 'addPresenter',
      },
      reportName: 'CampOfficerSelectionsExport',
    }
  },
  methods: {
    ...mapActions({
      getCampOfficerSelections: 'directory/getCampOfficerSelections',
      getCampOfficerPositions: 'directory/getCampOfficerPositions',
      getMemberChurches: 'campModule/getMemberChurches',
      loadCampOfficerSelectionsUrls: 'directory/loadCampOfficerSelectionsUrls',
      loadChurches: 'churchMinistry/loadChurches',
      setLoadingStatus: 'menu/setLoadingStatus',
      upsertDinnerSpeakers: 'directory/upsertDinnerSpeakers',
    }),
    async page_load() {
      try {
        this.setLoadingStatus(true)
        await Promise.all([
          await this.getCampOfficerPositions(this.userKey),
          this.setDefaultCampPosition(),
          await this.loadOfficerSelections(),
        ])
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async loadOfficerSelections() {
      let params = {
        org_key: this.userKey,
        ctp_key: this.campPosition,
      }
      await this.getCampOfficerSelections(params)
      await this.loadCampOfficerSelectionsUrls(params)
    },
    setDefaultCampPosition() {
      let presidentPosition = this.campOfficerPositions.find((position) => position.display_text == 'Camp President')
      this.campPosition = presidentPosition.value
    },
    addPresenter() {
      this.$router.push({ name: 'membership-dinner-speakers-upsert' })
    },
    loadTranslations() {
      this.addItems.text = this.translations.tcAddMember
      this.fields[0].label = this.translations.tcName
      this.fields[1].label = this.translations.tcCamp
    },
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await Promise.all([
        this.getViewTranslations(),
        this.getComponentTranslations('camp-select', 'data-table', 'data-table-member-churches'),
        this.getComponentTranslations('common.officer-titles'),
      ]).then((results) => {
        this.stripReadableText(results[2])
        const componentTranslations = results[1]
        this.$set(this.translations, 'components', componentTranslations)
        this.loadTranslations()
      })
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  computed: {
    ...mapGetters({
      campOfficerPositions: 'directory/campOfficerPositions',
      campOfficerSelections: 'directory/campOfficerSelections',
      csvUrl: 'directory/campOfficerSelectionsCSVUrl',
      held_visits_by_camp: 'churchMinistry/held_visits_by_camp',
      officerSelectStatekey: 'user/officerToolbarSelected',
      pdfUrl: 'card/restockingVisitPDFUrl',
      prefCulture: 'user/userPreferredCulture',
      userCampKey: 'user/userCampKey',
      userId: 'user/userId',
      userOrgKey: 'user/userOrgKey',
      userStateKey: 'user/userStateKey',
    }),
    optionCampPositions() {
      return this.campOfficerPositions.map((type) => {
        const translatedPosition = this.getTranslatedValue(type.value, 'officer-titles', type.display_text)
        return { value: type.value, text: translatedPosition }
      })
    },
    userKey() {
      return this.officerSelectStatekey.country_state
    },
    campOfficerSelectionsAlter() {
      let campOfficer = this.campOfficerSelections.map((x) => {
        return {
          camp_name: x.camp_name,
          vin_ind_key: x.ind_key,
          name: x.name,
          sort_name: x.sort_name,
        }
      })
      return campOfficer
    },
  },
  components: {
    campSelector: CampSelect,
    dataTable: DataTable,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/views/ChurchList.scss';

.cwidth {
  min-width: 252px !important;
}
</style>
